import React, { useCallback, useMemo } from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import { isString } from 'lodash';
import dayjs from 'dayjs';
import { Row, Col, Breadcrumb, Spin } from 'antd';
import NavigateNext from '@material-ui/icons/NavigateNext';
import DOMPurify from 'isomorphic-dompurify';

import { TitleRow, TitleCol, StyledContainer, StyledMessage, StyledMessageTitle, StyledMessageText } from './styles';
import { CalendarEvent } from './types';

import { useQuery } from '../../hooks/use-query';
import PublicLayout from '../../layout/public-layout';
import { StyledImage, StyledP } from '../../templates/basic-styles';
import { programShortName } from '../../siteContent';
import { NavigationWrapper } from '../../components/common/page-header';
import { formatDate, formatDateRangeToMonthDayDayYear, getTimeZoneByName } from '../../utils/date';
import { StyledH5 } from '../../components/home/section-styles';
import { Button } from '../../components';
import { getBriteEvent } from '../../apis/public/event-calendar';

const EventLocation = styled(StyledP)`
  margin-bottom: 1rem;
`;

const CenteredContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const StyledButton = styled(Button)`
  margin: 1rem 8px 0 0;
  max-width: 120px;
  width: 100%;
  && {
    font-size: 14px;
  }
`;

const MessageContainer = styled(StyledMessage)`
  margin: 50px 20px;
`;

const ImageCol = styled(Col)`
  margin-right: 15px;
`;

type BriteEventDetailProps = RouteComponentProps & {
  eventbriteEventId?: string;
};

export default function BriteEventDetail({ eventbriteEventId }: BriteEventDetailProps) {
  const { data: event, isLoading: briteEventsLoading } = useQuery(
    [getBriteEvent.QUERY_KEY, { id: eventbriteEventId || '' }],
    ({ queryKey }: any) => getBriteEvent(queryKey[1].id),
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  const timezone = (event && getTimeZoneByName(event.event_timezone.timezone_value)?.value) || '';

  const generateBreadcrumb = useCallback((name?: string) => {
    return [
      <Link key="1" to={'/event-calendar'}>
        {programShortName} Event Calendar
      </Link>,
      name,
    ];
  }, []);

  return (
    <PublicLayout seoTitle="Event Calendar">
      <StyledContainer>
        <NavigationWrapper>
          <Breadcrumb separator={<NavigateNext />}>
            {generateBreadcrumb(event?.name).map((item, index) => (
              <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </NavigationWrapper>
        {briteEventsLoading && (
          <CenteredContainer>
            <Spin spinning />
          </CenteredContainer>
        )}
        {!briteEventsLoading && !event && (
          <MessageContainer>
            <StyledMessageTitle>No Results</StyledMessageTitle>
            <StyledMessageText>Sorry, there are no event with the specified id.</StyledMessageText>
          </MessageContainer>
        )}
        {event && (
          <>
            <Row>
              {isString(event.image) && (
                <ImageCol xs={24} sm={24} md={12} lg={8}>
                  <StyledImage src={event.image} alt={event.name} />
                </ImageCol>
              )}
              <Col xs={24} sm={24} md={11} lg={16}>
                <div>
                  <TitleRow>
                    <TitleCol>{event.name}</TitleCol>
                  </TitleRow>
                  <EventLocation>
                    {event.online_location ? (
                      <a
                        style={{ textDecoration: 'underline' }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={event.online_location}
                      >
                        Online Event
                      </a>
                    ) : (
                      event.location
                    )}
                  </EventLocation>
                  <StyledH5>
                    {formatDateRangeToMonthDayDayYear(event.start_date_time, event.end_date_time, { month: 'MMM' })}
                  </StyledH5>
                  <StyledP>
                    {`${formatDate(event.start_date_time, 'hh:mma', timezone)} - ${formatDate(
                      event.end_date_time,
                      'hh:mma Z',
                      timezone
                    )}`}
                  </StyledP>
                  {!dayjs(event.end_date_time).isBefore(dayjs()) && (
                    <StyledButton type="primary-blue">
                      <a href={event.eventbrite_link} target="_blank" rel="noopener noreferrer">
                        Register
                      </a>
                    </StyledButton>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <StyledP
                  style={{ marginTop: '16px' }}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(event.description),
                  }}
                />
              </Col>
            </Row>
          </>
        )}
      </StyledContainer>
    </PublicLayout>
  );
}
